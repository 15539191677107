import './Background.scss';

const Background = () => {


    return (
        <div className='bg'>
            <div className='bg_dot_list'>
                <span className="dot" id='dot_1'></span>
                <span className="dot" id='dot_2'></span>
                <span className="dot" id='dot_3'></span>
                <span className="dot" id='dot_4'></span>
                <span className="dot" id='dot_5'></span>
                <span className="dot" id='dot_6'></span>
                <span className="dot" id='dot_7'></span>
            </div>
        </div>
    )
};

export default Background; 