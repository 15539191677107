import CardProject from "../cardProject/CardProject"
import mu_nutrition from "../../assets/murielBerliner.png"
import hrnet from "../../assets/HRnet.png"
import kasa from "../../assets/Kasa.png"
import argentBank from "../../assets/ArgentBank.png"
import OhMyFood from "../../assets/ohmyfood.png"
import lesVariations from "../../assets/lesVariations.png"
import dfacto from "../../assets/Dfacto.png"
import sustainity from "../../assets/Sustainity.png"

import "./Projets.scss"

const Projets = () => {

    return (
        <section className="Projets" id="Projets">
            <h2 id="Projets_titre" className="titre">Projet en cours</h2>
            <div className="Projets_cardsList" >
                <CardProject
                    imageLink={lesVariations}
                    title={"Les Variations"}
                    description={"Création d’un site d’e-commerce pour une boutique de bijoux."}
                    techno={"React, Figma, Firebase"}
                    id="CardProject_2"
                    link={"https://www.figma.com/file/aOZpmdsJH48fma9V1majSj/LesVariations?node-id=97%3A2670&t=XFz5m7JTaJEOXvUC-1"}
                />
            </div>
            <h2 id="Projets_titre" className="titre">Mes projets</h2>
            <div className="Projets_cardsList" >
            <CardProject
                    imageLink={sustainity}
                    title={"Sustainity"}
                    description={"Sustainity est un site e-commerce pour la vente de produits promotionnels."}
                    techno={"React, Figma, Firebase"}
                    id="CardProject_1"
                    link={"https://www.sustainity.co/"}
                />
                <CardProject
                    imageLink={mu_nutrition}
                    title={"Muriel Berliner"}
                    description={"mu_nutrition est un site vitrine pour la diététicienne Muriel Berliner."}
                    techno={"HTML, CSS, JS"}
                    id="CardProject_2"
                    link={"https://mu-nutrition.com/"}
                />
                <CardProject
                    imageLink={dfacto}
                    title={"Dfacto"}
                    description={"Création d'un site vitrine pour la société Dfacto."}
                    techno={"React, Figma, Scss"}
                    id="CardProject_3"
                    link={"https://dfacto.be/"}
                />
                <CardProject
                    imageLink={hrnet}
                    title={"HRnet"}
                    description={"Hrnet est une application pour la gestion des employés (projet OpenClassrooms)"}
                    techno={"React, redux"}
                    id="CardProject_4"
                    link={"https://github.com/HaydenLeDev/Projet14-rhnet"}
                />
                <CardProject
                    imageLink={kasa}
                    title={"Kasa"}
                    description={"Application pour la présentation de logement (projet OpenClassrooms)"}
                    techno={"React"}
                    id="CardProject_5"
                    link={"https://github.com/HaydenLeDev/kasa"}
                />
                <CardProject
                    imageLink={argentBank}
                    title={"ArgentBank"}
                    description={"Visualisation d'un compte bancaire (projet OpenClassrooms)"}
                    techno={"React, Api"}
                    id="CardProject_6"
                    link={"https://github.com/HaydenLeDev/Projet13-Bank/tree/main/projet13-bank-front-end"}
                />
                <CardProject
                    imageLink={OhMyFood}
                    title={"OhMyFood"}
                    description={"Site de présentation pour la commande de repas. (projet OpenClassrooms)"}
                    techno={"HTML, SCSS"}
                    id="CardProject_7"
                    link={"https://github.com/HaydenLeDev/OpenClassroom/tree/main/03-OhMyFood"}
                />
            </div>
        </section>
    )
}

export default Projets