import './CustomBouton.scss';

const CustomBouton = ({text}) => {
    return(
        <a className="CustomBouton" href="#Contact">
            {text}
        </a>
    )
}

export default CustomBouton