import "./CardProject.scss"

const CardProject = ({imageLink, title, description, techno, id, link}) => {
    return(
        <a className="CardProject reveal" id={id} href={link}> 
            <div className="CardProject_information">
                <div className="CardProject_information_text">
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
                <div className="CardProject_information_techno">
                    <p>{techno}</p>
                </div>
            </div>
            <img className="CardProject_image" src={imageLink} alt={description}></img>
        </a>
    )
}

export default CardProject