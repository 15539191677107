import "./Contact.scss"

const Contact = () => {
    
    return (
        <section className="Contact" id="Contact" data-scroll-section> 
            <h2 className="titre">Me contacter</h2>
            <div className="Contact_text">
            <p>Vous pouvez me contacter à l’adresse:</p>
            <a href="mailto:loicroth.pro@gmail.com" className="Contact_text_mail">loicroth.pro@gmail.com</a>
            </div>
        </section>
    )
}

export default Contact