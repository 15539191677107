import linkPhoto from "../../assets/profil.png"
import CustomBouton from "../boutton/CustomBouton"
import "./Profil.scss"

const Profil = () => {
    return (
        <section className="Profil" id="Profil">
            <div className="Profil_left">
                <img src={linkPhoto} alt="Profil"></img >
            </div>
            <div className="Profil_right">
                <h2 id="Profil_titre" className="titre">Hello World</h2>
                <div className="Profil_right_textes">
                    <p>Développeur freelance spécialisé dans la conception de sites et d'applications web je m’attelle à la réalisation de vos projets. Depuis l’obtention de mon diplôme, j’ai enchaîné les projets me permettant de collaborer avec diverses personnes. </p>

                        <p>    De l’analyse à la réalisation de votre projet je serais heureux de vous accompagner.
                            En plus de la conception de site web mon expertise digitale s’étend aux domaines du SEO jusqu’au SEA.</p>

                        <p>   Mes compétences en SEO permettent d’avoir un référencement naturel optimal ainsi qu’une bonne indexation dans les moteurs de recherche.</p>

                        <p>  Étant soucieux de l'expérience utilisateur et du design, j'apporte une attention particulière aux détails pour offrir une expérience utilisateur agréable.</p>

                        <p>  Il m’est important de travailler en étroite collaboration avec vous pour fournir un produit final qui répond parfaitement à vos attentes et à vos besoins !</p>

                        <p>  Au plaisir de collaborer avec vous !</p>
                </div>
                <CustomBouton text={"Me contacter"} />
            </div>
        </section>
    )
}

export default Profil