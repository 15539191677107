import { useRef, useState } from "react"
import "./BurgerMenu.scss"

const BurgerMenu = () => {
    const ref = useRef(null)
    const [active, setActive] = useState(false);
    const isActive = active ? "menu-icon menu-icon_active" : "menu-icon"  
    const isActiveBg = active ? "mobile_menu mobile_menu_active" : "mobile_menu"  

    const OnClick = () => {
        setActive(!active)
    }

    console.log(active)
    return (
        <>
            <button className="button_menu" onClick={OnClick}>
                <div className={isActive}>
                    <span ></span>
                    <span ></span>
                    <span ></span>
                </div>
            </button>
            <div className={isActiveBg} ref={ref}>
                <nav className="mobile_menu_nav">
                    <ul className="mobile_menu_nav_liste">
                        <li className="header_p"><a href='#Profil' onClick={OnClick}>Acceuil</a><span className="underline"></span></li>
                        <li className="header_p"><a href='#arrow' onClick={OnClick}>Projets</a><span className="underline"></span></li>
                        <li className="header_p"><a href='#Contact' onClick={OnClick}>Contact</a><span className="underline"></span></li>
                    </ul>
                </nav>
            </div>
        </>

    )
}

export default BurgerMenu