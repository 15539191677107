import linkArrow from "../../assets/arrow.png";
import "./Arrow.scss"

const Arrow = () => {
    return(
        <div className="arrow" id="arrow" data-scroll-section>
            <img className="arrow_img" src={linkArrow} alt="fleche pour scroll"></img>
        </div>   
    )
}

export default Arrow