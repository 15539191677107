import BurgerMenu from './burgerMenu/BurgerMenu';
import './Header.scss';

const Header = () => {
    
    return (
        <header id="header">
            <h1 className="header_p"><a href='#Profil'>./LoïcRoth</a></h1>
            <nav className="header_menu">
                <ul>
                    <li className="header_p"><a href='#Profil'>Acceuil</a><span className="underline"></span></li>
                    <li className="header_p"><a href='#Projets'>Projets</a><span className="underline"></span></li>
                    <li className="header_p"><a href='#Contact'>Contact</a><span className="underline"></span></li>
                </ul>
            </nav>
            <BurgerMenu/>
        </header>
    )
};

export default Header;