import "./Footer.scss"
import icone_twitter from "../../assets/icone_twitter.png"
import icone_git from "../../assets/icone_git.png"
import icone_linkedin from "../../assets/icone_linkedin.png"
const Footer = () => {
    return(
        <section className="Footer">
            <div className="Footer_icones">
                <a href="https://twitter.com/HaydenLeDev"><img src={icone_twitter} alt="Twitter"></img></a>
                <a href="https://www.linkedin.com/in/lo%C3%AFc-roth-354269247/"><img src={icone_linkedin} alt="Linkedin"></img></a>
                <a href="https://github.com/HaydenLeDev"><img src={icone_git} alt="Git"></img></a>
            </div>
            <p>Loic Roth - Contact - Mention Légale </p>
        </section>
    )
}

export default Footer