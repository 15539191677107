import Arrow from '../components/arrow/Arrow';
import Background from '../components/background/Background';
import Header from '../components/header/Header';
import Profil from '../components/profil/Profil';
import Projets from '../components/projets/Projets';
import Contact from '../components/contact/Contact';
import Footer from '../components/footer/Footer';
import gsap from 'gsap';
import { ScrollToPlugin, ScrollTrigger } from 'gsap/all';
import { useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const slideFromBottom = (elem) => {
    gsap.fromTo(
        elem,
        {
            opacity: 0,
            y: -30,
        },
        {
            opacity: 1,
            x: 0,
            duration:0.2,
            scrollTrigger: {
                trigger: elem,
                start: "top bottom",
                end: "bottom top"
            }
        }
    )
}


const Home = () => {
    
    useEffect(() => {
        slideFromBottom("#CardProject_1")
        slideFromBottom("#CardProject_2")
        slideFromBottom("#CardProject_3")
        slideFromBottom("#CardProject_4")
        slideFromBottom("#CardProject_5")
        slideFromBottom("#CardProject_6")
        slideFromBottom("#Projets_titre")
        slideFromBottom("#Contact")

    }, [])

    return (
        <main>
            <Header />
            <Background />
            <Profil />
            <Arrow />
            <Projets />
            <Contact />
            <Footer />
        </main>
    )
}

export default Home